import React from 'react'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
import cn from 'classnames'
import { Link } from 'gatsby'
import SEO from 'components/seo'

export default withMainLayout(PrivacyPolicyPage)

function PrivacyPolicyPage() {
  return (
    <div className={cn(css.page, 'container')}>
      <SEO
        title='Политика в отношении обработки персональных данных'
      />
       <p align="center">
         <strong>ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
       </p>
       <p align="center">
         <strong>1. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
       </p>
       <p align="justify">
         Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок Обработки Персональных данных и меры по обеспечению безопасности Персональных данных, предпринимаемые МБФ «ОР АВНЕР» (далее – «Оператор»).
       </p>
       <p align="justify">
         Посещение Сайта и использование его функционала, в том числе регистрация на Сайте (заполнение регистрационной формы, проставление «галочки» в соответствующем поле), размещение контактной и иной информации на Сайте, отправка запроса на получение Подарка является полным безоговорочным акцептом Посетителем настоящей Политики, Пользовательского соглашения, а также любых опубликованных или согласованных с Посетителем условий, размещенных Оператором на Сайте.
       </p>
       <p align="justify">
         <strong>1.1.</strong> Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его Персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
       </p>
       <p align="justify">
         <strong>1.2.</strong> Настоящая политика Оператора в отношении обработки Персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о Посетителях и Третьих лицах Сайта <Link to="/">chanuka.ru</Link>.
       </p>
       <p align="center">
         <strong>2. ОСНОВНЫЕ ТЕРМИНЫ, ИСПОЛЬЗУЕМЫЕ В ПОЛИТИКЕ</strong>
       </p>
       <p align="justify">
         <strong>2.1.</strong> Для целей применения и толкования настоящей Политики используются определенные ниже основные термины (если в Политике прямо не указано иное). В тексте Политики эти термины могут быть указаны с большой или маленькой буквы в единственном или множественном числе, а также в виде сокращений. 
       </p>
       <ul>
         <li>
           <p align="justify">
             <strong>Сайт</strong> <Link to="/"><strong>chanuka.ru</strong></Link> – интернет-ресурс, состоящий из совокупности графических и информационных материалов, а также программ для ЭВМ, баз данных и размещенной в них информации. Сайт содержится в информационной системе, обеспечивающей доступность указанной информации в сети Интернет по адресу (доменному имени): <Link to="/">chanuka.ru</Link>, включая все уровни указанного домена, как функционирующие на дату регистрации Пользователя, так и создаваемые в течение всего срока действия домена. 
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Оператор</strong> – МБФ «ОР АВНЕР» (Адрес местонахождения: 127055, г. Москва, ул. Новосущевская, д. 15, ИНН: 7701166751, ОГРН: 1027739515650), а также его уполномоченные представители.
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Посетитель</strong> – лицо, осуществляющее доступ к Сайту посредством сети Интернет и использующее Сайт.
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Пользователь</strong> – авторизованный Посетитель, принявший условия Пользовательского соглашения, размещенного в сети Интернет по адресу: <Link to="/terms-of-service/">chanuka.ru/terms-of-service</Link> , и использующий Сайт в целях запроса Подарка для себя и Третьих лиц.
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Третье лицо</strong> – лицо, в пользу которого Посетителем (Пользователем) была подана заявка на направление Подарка.
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Пользовательское соглашение </strong> – документ, в котором определены права и обязанности Посетителя и Оператора (администратора).  Условия Пользовательского соглашения доступны по адресу: <Link to="/terms-of-service">chanuka.ru/terms-of-service</Link>.
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Подарок</strong> – вещь, которую Оператор безвозмездно направляет Пользователю. Стоимость Подарка не превышает 3 000 (трех тысяч) рублей.
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Личный кабинет Пользователя</strong> (далее – <strong>«Личный кабинет»</strong>) – раздел Сайта, созданный персонально для конкретного Пользователя после его регистрации и авторизации на Сайте.
           </p>
         </li>
         <li>
           <p>
             <strong>Форма обратной связи</strong> — веб-форма, доступная на Сайте и предназначенная для направления электронных сообщений Оператору.
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Персональные данные</strong> – любая информация, относящаяся прямо или косвенно к определенному или определяемому Посетителю Сайта и (или) Третьему лицу, указанному Посетителем (Пользователем).
           </p>
         </li>
         <li>
           <p align="justify">
             <strong>Обработка Персональных данных</strong> (далее также «Обработка») – действие (операция) или совокупность действий (операций) с Персональными данными, определенные в настоящей Политике.
           </p>
         </li>
       </ul>
       <p align="justify">
         <strong>2.2.</strong> В настоящей Политике могут быть указаны и иные термины и определения, которые следует толковать неотделимо от смысла текста Политики и указанных в пункте 1.1. Политики определений. Термины и определения, если иное не установлено в Политике, употребляются в том смысле, который содержится в части 4 Гражданского Кодекса РФ, Федеральном законе от 27.07.2006 N 152-ФЗ «О персональных данных».
       </p>
       <p align="center">
         <strong>3. СОСТАВ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
       </p>
       <p align="justify">
         <strong>3.1.</strong> Оператор может Обрабатывать следующие Персональные данные Посетителя и Третьего лица:
       </p>
       <p align="justify">
         <strong>3.1.1.</strong>
         Фамилия, имя, отчество;
       </p>
       <p align="justify">
         <strong>3.1.2.</strong>
         Дата рождения;
       </p>
       <p align="justify">
         <strong>3.1.3.</strong>
         Месяц рождения;
       </p>
       <p align="justify">
         <strong>3.1.4.</strong>
         Год рождения;
       </p>
       <p align="justify">
         <strong>3.1.5.</strong>
         Пол;
       </p>
       <p align="justify">
         <strong>3.1.6.</strong>
         Адреса электронной почты;
       </p>
       <p align="justify">
         <strong>3.1.7. </strong>
         Номера телефонов;
       </p>
       <p align="justify">
         <strong>3.1.8.</strong>
         Фотографии;
       </p>
       <p align="justify">
         <strong>3.1.9.</strong>
         Адрес местонахождения;
       </p>
       <p align="justify">
         <strong>3.1.10</strong>
         Адрес места жительства;
       </p>
       <p align="justify">
         <strong>3.1.11.</strong>
         Сведения об образовании;
       </p>
       <p align="justify">
         <strong>3.1.12.</strong>
         Сведения о состоянии здоровья;
       </p>
       <p align="justify">
         <strong>3.1.13.</strong>
         Сведения об имущественном положении;
       </p>
       <p align="justify">
         <strong>3.1.14.</strong>
         Сведения об учетных записях в социальных сетях ({/* Facebook, Instagram,  */}Вконтакте, Одноклассники и др.) и на иных сервисах (Google, YouTube и т.д.);
       </p>
       <p align="justify">
         <strong>3.1.15.</strong>
         Сведения об электронных устройствах и программном обеспечении, используемых для доступа к Сайту; 
       </p>
       <p align="justify">
         <strong>3.1.16.</strong>
         Персональные данные, сделанные общедоступными Посетителем и (или) Третьим лицом.
       </p>
       <p align="justify">
         <strong>3.2.</strong> Оператор не имеет права получать и Обрабатывать Персональные данные Посетителя, Третьего лица, содержащие сведения о расовой, национальной принадлежности, политических взглядах, религиозных и философских убеждениях, состоянии здоровья, кроме как с письменного согласия Посетителя, Третьего лица, за исключением случаев, когда вышеуказанные данные предоставлены Посетителем, Третьим лицом другим Посетителям, сделаны Посетителем, Третьим лицом общедоступными, а также в других случаях, предусмотренных законодательством Российской Федерации.
       </p>
       <p align="justify">
         <strong>3.3.</strong> На Сайте происходит сбор и Обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика, Google Аналитика и других).
       </p>
       <p align="center">
         <strong>4. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
       </p>
       <p align="justify">
         <strong>4.1.</strong>
         Цели Обработки Персональных данных:
       </p>
       <p align="justify">
         <strong>4.1.1.</strong>
         Регистрация Посетителей на Сайте;
       </p>
       <p align="justify">
         <strong>4.1.2. </strong>
         Обеспечение и поддержание связи между Оператором и Посетителем;
       </p>
       <p align="justify">
         <strong>4.1.3. </strong>
         Надлежащее функционирование Сайта;
       </p>
       <p align="justify">
         <strong>4.1.4.</strong>
         Направление Подарка Посетителю или Третьему лицу в соответствии с Пользовательским соглашением;
       </p>
       <p align="justify">
         <strong>4.1.5.</strong> Исполнение договора, стороной которого либо выгодоприобретателем или поручителем по которому является Посетитель или Третье лицо, а также для заключения договора по инициативе Посетителя (в том числе при заключении договора от Третьего лица) или договора, по которому Посетитель или Третье лицо будут являться выгодоприобретателями или поручителями;
       </p>
       <p align="justify">
         <strong>4.1.6.</strong> Направление информационной, рекламной и иной рассылки на адрес электронной почты, аккаунт в социальной сети, на номер телефона, указанные Посетителем (в том числе данные Третьего лица), устройство, используемое Посетителем при регистрации на Сайте (в том числе с помощью push-уведомлений), Личный кабинет на Сайте.
       </p>
       <p align="justify">
         <strong>4.1.7.</strong> Рассмотрение и учет обращений (запросов, предложений, комментариев, претензий, благодарностей), поступающих Оператору от Посетителей, Третьих лиц, контрагентов и других лиц, а также осуществления информационного обслуживания указанных лиц;
       </p>
       <p align="justify">
         <strong>4.1.8.</strong> Улучшение качества обслуживания и модернизации Сайта Оператором;
       </p>
       <p align="justify">
         <strong>4.1.9.</strong> Реализация партнерских программ с иными лицами (кроме Посетителя и Третьего лица);
       </p>
       <p align="justify">
         <strong>4.1.10.</strong> Предложение Оператором своих услуг действительным и потенциальным контрагентам (клиентам, партнерам), а также участие в процедурах закупок указанных лиц и ведение Оператором деловых переговоров с указанными лицами;
       </p>
       <p align="justify">
         <strong>4.1.11.</strong> Осуществление договорной деятельности в рамках возникновения, изменения и прекращения правоотношений между Оператором и его партнерами, клиентами и прочими контрагентами – как физическими, так и юридическими лицами;
       </p>
       <p align="justify">
         <strong>4.1.12.</strong> Направление таргетированной рекламы и решения иных подобных задач, в том числе иными (кроме Оператора) лицами;
       </p>
       <p align="justify">
         <strong>4.1.13.</strong> Регистрация корреспонденции;
       </p>
       <p align="justify">
         <strong>4.1.14.</strong> Организация и осуществление независимой проверки бухгалтерской (финансовой) отчетности Оператора в целях выражения мнения о достоверности такой отчетности;
       </p>
       <p align="justify">
         <strong>4.1.15.</strong> Осуществление и выполнение возложенных законодательством Российской Федерации на Оператора функций, полномочий и обязанностей;
       </p>
       <p align="justify">
         <strong>4.1.16.</strong> Участие Посетителя и (или) Третьего лица и (или) Оператора в конституционном, гражданском, административном, уголовном судопроизводстве, судопроизводстве в арбитражных судах;
       </p>
       <p align="justify">
         <strong>4.1.17.</strong> Исполнение судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;
       </p>
       <p align="justify">
         <strong>4.1.18.</strong> Иные цели, определяемые в порядке, установленном в Пользовательском соглашении.
       </p>
       <p align="justify">
         <strong>4.2.</strong> Кроме целей, определённых в п. 4.1. настоящей Политики, конкретные цели также могут быть указаны в заполняемой Посетителем Форме обратной связи.
       </p>
       <p align="justify">
         <strong>4.3.</strong> При разработке Форм обратной связи Оператор обеспечивает соответствие содержания и объема обрабатываемых Персональных данных заявленным целям Обработки.
       </p>
       <p align="justify">
         <strong>4.4.</strong> Обезличенные данные Посетителей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Посетителей на сайте, улучшения качества сайта и его содержания.
       </p>
       <p align="center">
         <strong>5. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
       </p>
       <p align="justify">
         <strong>5.1.</strong> Правовыми основаниями Обработки Персональных данных является согласие Посетителя на Обработку его Персональных данных, договоры и соглашения (включая Пользовательское соглашение), заключенные между Посетителем и Оператором, а также ст. 10 Федерального закона от 27 июля 2006 г. N 149-ФЗ «Об информации, информационных технологиях и о защите информации», ст. 18 Федерального закона от 13 марта 2006 г. N 38-ФЗ «О рекламе», а также иные положения нормативно-правовых актов, в зависимости от конкретных целей обработки. В случае передачи Посетителем Персональных данных Третьих лиц правовым основанием Обработки Персональных данных является п. 5 ч. 1 ст. 6 ФЗ «О персональных данных.
       </p>
       <p align="justify">
         <strong>5.2.</strong> Оператор Обрабатывает Персональные данные Посетителя только в случае их заполнения и/или отправки Посетителем самостоятельно через специальные Формы обратной связи, расположенные на Сайте, а также в случае, если Посетитель сам сделал свои Персональные данные общедоступными.
       </p>
       <p align="justify">
         <strong>5.3.</strong> Направляя Оператору Персональные данные Третьего лица, Посетитель подтверждает, что предоставляет Персональные данные с согласия Третьего лица.
       </p>
       <p align="justify">
         <strong>5.4.</strong> Заполняя соответствующие Формы обратной связи и/или отправляя свои Персональные данные Оператору, Посетитель выражает свое согласие с данной Политикой.
       </p>
       <p align="justify">
         <strong>5.5.</strong> Оператор не осуществляет проверку достоверности, предоставляемой Посетителем информации (в том числе информации, относящейся к Третьему лицу), а также дееспособность Посетителя и Третьего лица.
       </p>
       <p align="justify">
         <strong>5.4. </strong> При сборе Персональных данных Оператор всегда исходит из следующего:
       </p>
       <p align="justify">
         <strong>5.4.1.</strong> Все Персональные данные принадлежат только Посетителю, заполнившему (предоставившему) необходимую информацию при регистрации на Сайте, а также заполнившему Форму обратной связи или Третьему лицу, согласие которого на заполнение (предоставление) получено Посетителем;
       </p>
       <p align="justify">
         <strong>5.4.2.</strong> Посетитель сообщил достоверные и актуальные Персональные данные, как относящиеся к самому Посетителю, так и к Третьему лицу.
       </p>
       <p align="justify">
         <strong>5.5.</strong> Оператор Обрабатывает обезличенные данные о Посетителе в случае, если это разрешено в настройках браузера Посетителя (включено сохранение файлов «cookie» и использование технологии JavaScript).
       </p>
       <p align="center">
         <strong>6. СПОСОБЫ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
       </p>
       <p align="justify">
         <strong>6.1.</strong> Обработка Персональных данных Оператором включает в себя сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных.
       </p>
       <p align="justify">
         <strong>6.2. </strong> Оператор осуществляет Обработку Персональных данных с использованием средств автоматизации и без использования средств автоматизации.
       </p>
       <p align="justify">
         <strong>6.3. </strong> При сборе Персональных данных Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, прямо предусмотренных действующим законодательством Российской Федерации о Персональных данных.
       </p>
       <p align="justify">
         <strong>6.4.</strong> Срок Обработки Персональных данных является неограниченным. Посетитель может в любой момент отозвать свое согласие на Обработку Персональных данных, направив Оператору уведомление в соответствии с п. 7.2. настоящей Политики.
       </p>
       <p align="justify">
         <strong>6.5.</strong> Безопасность Персональных данных, которые Обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты Персональных данных. Обеспечение безопасности Персональных данных достигается, в частности, следующими способами:
       </p>
       <p align="justify">
         <strong>6.5.1.</strong> Назначением ответственного лица за организацию Обработки Персональных данных;
       </p>
       <p align="justify">
         <strong>6.5.2.</strong> Осуществлением внутреннего контроля и/или аудита соответствия обработки Персональных данных Федеральному закону от 27.07.2006 № 152-ФЗ «О персональных данных» и принятыми в соответствии с ним нормативными правовыми актами, требованиями к защите Персональных данных, локальными актами Оператора;
       </p>
       <p align="justify">
         <strong>6.5.3.</strong> Ознакомлением сотрудников Оператора, непосредственно осуществляющих Обработку Персональных данных, с положениями законодательства Российской Федерации о Персональных данных, в том числе с требованиями к защите Персональных данных, локальными актами в отношении Обработки Персональных данных и (или) обучением указанных сотрудников;
       </p>
       <p align="justify">
         <strong>6.5.4.</strong> Определением угроз безопасности Персональных данных при их Обработке в информационных системах Персональных данных;
       </p>
       <p align="justify">
         <strong>6.5.5.</strong> Применением организационных и технических мер по обеспечению безопасности Персональных данных при их Обработке в информационных системах Персональных данных, необходимых для выполнения требований к защите Персональных данных;
       </p>
       <p align="justify">
         <strong>6.5.6.</strong> Оценкой эффективности принимаемых мер по обеспечению безопасности Персональных данных до ввода в эксплуатацию информационной системы Персональных данных;
       </p>
       <p align="justify">
         <strong>6.5.7.</strong> Учетом машинных носителей Персональных данных;
       </p>
       <p align="justify">
         <strong>6.5.8.</strong> Выявлением фактов несанкционированного доступа к Персональным данным и принятием соответствующих мер;
       </p>
       <p align="justify">
         <strong>6.5.9.</strong> Восстановлением Персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
       </p>
       <p align="justify">
         <strong>6.5.10.</strong> Установлением правил доступа к Персональным данным, обрабатываемым в информационной системе Персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с Персональными данными в информационной системе Персональных данных;
       </p>
       <p align="justify">
         <strong>6.5.11.</strong> Контролем над принимаемыми мерами по обеспечению безопасности Персональных данных и уровнем защищенности информационных систем Персональных данных.
       </p>
       <p align="justify">
         <strong>6.6.</strong> Обязанности сотрудников Оператора, осуществляющих Обработку и защиту Персональных данных, а также их ответственность, определяются в соответствии с законодательством РФ.
       </p>
       <p align="justify">
         <strong>6.7.</strong> Для достижения заявленных в настоящей Политике целей Обработки Персональных данных Оператор может сообщать Персональные данные другим лицам, в том числе своим аффилированным компаниям, а также поручать обработку Персональных данных другим лицам на основании договора, содержащего условия о конфиденциальности и иные обязательные положения, предписанные законодательством Российской Федерации.
       </p>
       <p align="justify">
         <strong>6.8.</strong> Персональные данные могут быть переданы другим лицам при согласии Посетителя. В тоже время согласия Посетителя на передачу его Персональных данных и Персональных данных Третьих лиц другим лицам не требуется:
       </p>
       <p align="justify">
         <strong>6.8.1.</strong> В целях предупреждения угрозы жизни и здоровью Посетителя и (или) Третьего лица;
       </p>
       <p align="justify">
         <strong>6.8.2.</strong> Когда согласие Посетителя (в том числе на передачу) его Персональных данных или Персональных данных Третьего лица (при этом Посетитель подтвердил согласие Третьего лица) получено от него другими лицами в письменном виде при заключении договора с Оператором;
       </p>
       <p align="justify">
         <strong>6.8.3.</strong> Когда другие лица оказывают услуги Оператору на основании заключенных договоров, а передача Персональных данных необходима для исполнения договора, стороной которого, либо выгодоприобретателем или поручителем по которому является Посетитель и (или) Третье лицо;
       </p>
       <p align="justify">
         <strong>6.8.4.</strong> В иных случаях, установленных действующим законодательством РФ.
       </p>
       <p align="justify">
         <strong>6.9.</strong> Ответы на запросы других лиц (в том числе, юридических лиц) в пределах их компетенции и предоставленных полномочий даются в письменной форме, на бланке Оператора и в том объеме, который позволяет не разглашать излишний объем Персональных данных Посетителей и (или) Третьих лиц.
       </p>
       <p align="justify">
         <strong>6.10.</strong> Сотрудники Оператора, передающие документы (или иные материальные носители информации) с Персональными данными Посетителей и (или) Третьих лиц другим лицам, должны передавать их либо с составлением двустороннего акта приема-передачи документов (иных материальных носителей информации), содержащих Персональные данные Посетителей и (или) Третьих лиц, либо иным способом, позволяющим подтвердить факт и дату передачи документов (или иных материальных носителей информации), содержащих Персональные данные Посетителей и (или) Третьих лиц. При использовании любого из указанных способов должны выполняться следующие условия:
       </p>
       <p align="justify">
         <strong>6.10.1.</strong> Уведомление лица, получающего данные документы об обязанности использования полученных Персональных данных лишь в целях, для которых они сообщены;
       </p>
       <p align="justify">
         <strong>6.10.2.</strong> Предупреждение об ответственности за противоправную Обработку Персональных данных в соответствии с действующим законодательством Российской Федерации.
       </p>
       <p align="justify">
         <strong>6.11.</strong> Передача документов (иных материальных носителей информации), содержащих Персональные данные Посетителей и (или) Третьих лиц, осуществляется при наличии у лица, уполномоченного на их получение следующего набора документов:
       </p>
       <p align="justify">
         <strong>6.11.1. </strong> Договор, стороной или выгодоприобретателем которого является Оператор;
       </p>
       <p align="justify">
         <strong>6.11.2. </strong> Соглашение о соблюдении конфиденциальности информации либо наличие в договоре Оператора с другим лицом пунктов о соблюдении конфиденциальности информации, в том числе, предусматривающих обеспечение конфиденциальности Персональных данных Посетителей и (или) Третьих лиц.
       </p>
       <p align="justify">
         <strong>6.12.</strong> Оператор установил следующие условия прекращения Обработки Персональных данных:
       </p>
       <p align="justify">
         <strong>6.12.1</strong> Достижение целей Обработки Персональных данных;
       </p>
       <p align="justify">
         <strong>6.12.2</strong> Утрата необходимости в достижении целей Обработки Персональных данных;
       </p>
       <p align="justify">
         <strong>6.12.3</strong> Предоставление Посетителем или его законным представителем (Третьим лицом или его представителем) сведений, подтверждающих, что Персональные данные являются незаконно полученными или не являются необходимыми для заявленной цели Обработки;
       </p>
       <p align="justify">
         <strong>6.12.4</strong> Невозможность обеспечения правомерности Обработки Персональных данных;
       </p>
       <p align="justify">
         <strong>6.12.5</strong> Отзыв Посетителем и (или) Третьим лицом согласия на Обработку Персональных данных, если сохранение Персональных данных более не требуется для целей Обработки Персональных данных;
       </p>
       <p align="justify">
         <strong>6.12.6</strong> Истечение сроков исковой давности для правоотношений, в рамках которых осуществляется либо осуществлялась Обработка Персональных данных.
       </p>
       <p align="center">
         <strong>7. ПРАВА ПОСЕТИТЕЛЯ И ТРЕТЬЕГО ЛИЦА</strong>
       </p>
       <p>
         <strong>7.1.</strong> Посетитель и Третье лицо имеют право:
       </p>
       <p align="justify">
         <strong>7.1.1.</strong> На получение сведений об Обработке своих Персональных данных Оператором;
       </p>
       <p align="justify">
         <strong>7.1.2.</strong> В случае выявления неточностей, требовать от Оператора уточнения Персональных данных.
       </p>
       <p align="justify">
         <strong>7.1.3.</strong> Направить Оператору запрос на удаление или изменение любой предоставляемой информации (как являющейся Персональными данными, так и не являющейся ими).
       </p>
       <p align="justify">
         <strong>7.1.4.</strong> Направить Оператору отзыв своего согласия на Обработку Персональных.
       </p>
       <p align="justify">
         <strong>7.1.5.</strong> Направить Оператору отзыв согласия на получение информационных рассылок и рекламных материалов.
       </p>
       <p align="justify">
         <strong>7.1.6.</strong> Получить любые разъяснения по интересующим вопросам, касающимся Обработки Персональных данных.
       </p>
       <p align="justify">
         <strong>7.1.7. </strong> Осуществлять иные права, предусмотренные законодательством Российской Федерации в области Персональных данных.
       </p>
       <p align="justify">
         <strong>7.2.</strong> Права, предусмотренные п. 7.1. настоящей Политики, реализуются путем направления Посетителем или Третьим лицом письма на юридический адрес Оператора, указанный в настоящей Политике, посредством АО «Почта России». Срок рассмотрения указанного письма (в том числе относящегося к реализации прав, предусмотренных п.п. 7.1.2.-7.1.5. настоящей Политики) – 30 (тридцать) календарных дней с момента его получения Оператором. 
       </p>
       <p align="justify">
         <strong>7.3. </strong> При получении запроса на удаление предоставленной Посетителем информации (в том числе информации о Третьем лице), а также получении отзыва согласия на Обработку Персональных данных Оператор вправе в соответствии с п. 5 ч. 1 ст. 6 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» продолжить Обработку такой информации, Персональных данных в целях исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является Посетитель и (или) Третье лицо (в том числе направление Подарка в соответствии с Пользовательским соглашением), а также для заключения договора по инициативе Посетителя и (или) Третьего лица или договора, по которому Посетитель и (или) Третье лицо будут являться выгодоприобретателем или поручителем.
       </p>
       <p align="justify">
         <strong>7.4. </strong> Право Посетителя и Третьего лица на доступ к их Персональным данным может быть ограничено в соответствии с федеральными законами, в том числе, если доступ Посетителя и (или) Третьего лица к их Персональным данным нарушает права и законные интересы других лиц.
       </p>
       <p align="justify">
         <strong>7.5.</strong> Для реализации и защиты своих прав и законных интересов Посетитель и Третье лицо имеет право обратиться к Оператору в порядке, предусмотренном п. 7.2. настоящей Политики. Оператор рассматривает любые обращения и жалобы со стороны субъектов персональных данных, тщательно расследует факты нарушений и принимает все необходимые меры для их немедленного устранения, наказания виновных лиц и урегулирования спорных и конфликтных ситуаций в досудебном порядке.
       </p>
       <p align="justify">
         <strong>7.6.</strong> Посетитель и Третье лицо вправе обжаловать действия или бездействие Оператора путем обращения в уполномоченный орган по защите прав субъектов Персональных данных.
       </p>
       <p align="justify">
         <strong>7.7.</strong> Посетитель и Третье лицо имеют право на защиту своих прав и законных интересов, в том числе на возмещение убытков и/или компенсацию морального вреда в судебном порядке. 
       </p>
       <p align="center">
         <strong>8. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</strong>
       </p>
       <p align="justify">
         <strong>8.1.</strong> Сайт не является общедоступным источником Персональных данных. При этом в случае совершения Посетителями (или) Третьим лицом соответствующих действий их Персональные данные могут стать доступны неопределенному кругу лиц, о чем Посетитель дает свое согласие.
       </p>
       <p align="justify">
         <strong>8.2. </strong> Оператор информирует Посетителей, что настоящая Политика применима только к Сайту. Оператор не контролирует и не несет ответственность за использование любых ресурсов (сайтов, порталов, сервисов, платформ и т.д.) других лиц, на которые Посетитель может по своему усмотрению и на свой риск перейти по ссылкам, размещенным посредством Сайта другими Посетителям Сайта.
       </p>
       <p align="center">
         <strong>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
       </p>
       <p align="justify">
         <strong>9.1.</strong> Настоящее Политика вступает в силу с момента утверждения, вводится в действие Оператором и действует бессрочно (до отмены или замены ее новой версией Политики).
       </p>
       <p align="justify">
         <strong>9.2.</strong> Действующая редакция настоящей Политики размещена по адресу <Link to="/privacy-policy/">chanuka.ru/privacy-policy</Link> с целью обеспечения неограниченного доступа к ней.
       </p>
       <p align="justify">
         <strong>9.3.</strong> Оператор имеет право вносить изменения в Политику. Новая версия Политики вступает в силу с момента ее публикации по адресу <Link to="/privacy-policy/">chanuka.ru/privacy-policy</Link>, если иное не предусмотрено новой версией Политики. Продолжение использования Сайта Посетителем означает его согласие с внесенными изменениями.
       </p>
       <p align="justify">
         <strong>9.4.</strong> Требования настоящей Политики распространяются на всех работников Оператора, имеющих доступ к Персональным данным, а также на всех Посетителей Сайта.
       </p>
    </div>
  )
}
